// @ts-nocheck
import { Column } from 'react-table'
import { Account } from '../../core/_models'
import { AccountCustomHeader } from './AccountCustomHeader'
import { AccountInfoCell } from './AccountInfoCell'
import { AccountLastLoginCell } from './AccountLastLoginCell'
import { UserActionsCell } from '../../../../patient-management/patients-list/table/columns/PatientActionsCell'

const accountsColumns: ReadonlyArray<Column<Account>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <AccountCustomHeader tableProps={props} title='Tài khoản' className='min-w-150px' />,
    id: 'name',
    Cell: ({ ...props }) => <AccountInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <AccountCustomHeader tableProps={props} title='Email' className='min-w-75px' />,
    accessor: 'email',
  },
  {
    Header: (props) => <AccountCustomHeader tableProps={props} title='Loại tài khoản' className='min-w-100px' />,
    accessor: 'role',
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Bệnh' className='min-w-125px' />,
  //   accessor: 'pathological_medical_history',
  // },  
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Bệnh lý' className='min-w-125px' />
  //   ),
  //   id: 'pathologies',
  //   Cell: ({...props}) => <PatientPathologiesCell pathologies={props.data[props.row.index].pathologies} />,
  // },
  // {
  //   Header: (props) => <AccountCustomHeader tableProps={props} title='Kế hoạch sức khoẻ' className='min-w-125px' />,
  //   accessor: 'existed_careplan',
  // },
  {
    Header: (props) => (
      <AccountCustomHeader tableProps={props} title='Truy cập' className='min-w-125px' />
    ),
    id: 'last_online',
    Cell: ({ ...props }) => <AccountLastLoginCell last_login={props.data[props.row.index].last_online} />,
  },
  {
    Header: (props) => (
      <AccountCustomHeader tableProps={props} title='Ngày tạo tài khoản' className='min-w-125px' />
    ),
    id: 'create_time',
    Cell: ({ ...props }) => <AccountLastLoginCell last_login={props.data[props.row.index].create_time} />,
  },
    
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Nhóm bệnh' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  {
    Header: (props) => (
      <AccountCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export { accountsColumns }
