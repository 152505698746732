import { Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../_metronic/layout/core"
import { KTCard, KTIcon } from "../../_metronic/helpers"
import { PatientsListWrapper } from "../modules/apps/patient-management/patients-list/PatientsList"
import { Account } from "../modules/profile/components/account/Account"
import PatientPage from "../modules/apps/patient-management/patient/PatientPage"

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Bệnh nhân',
        path: '/patient/list',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const PatientListPage = () => {
    return (
        <Routes>
            <Route
                path='list'
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs}>Danh sách bệnh nhân</PageTitle>
                        <PatientsListWrapper />
                        {/* <Overview /> */}
                    </>
                }
            />
            <Route
                path='messages'
                element={
                    <>
                        Not implement yet
                    </>
                }
            />
            <Route
                path='id/:uid/*'
                element={
                    <>
                        <PatientPage />
                    </>
                }
            />
        </Routes>
    )
}