import clsx from 'clsx'
import { FC, Fragment } from 'react'
import { shortListPathologies, stylePathology } from '../../../../../helpers/Common'

const PatientPathologiesCell = ({ pathologies }) => {
  // <> {two_steps && <div className='badge badge-light-success fw-bolder'>Enabled</div>}</>
  // console.log(pathologies)
  return (
    <>
    {
      pathologies && shortListPathologies(pathologies).map((pathology) => (
        <Fragment key={pathology}>
          <span className={clsx('badge', 'fw-bolder', stylePathology(pathology))}>{pathology}</span>
          {' '}
        </Fragment>
      ))
    }
    </>
  )
}

export { PatientPathologiesCell }
