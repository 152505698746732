/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import { useEffect, useState } from 'react'
import { PULSEOX_SUMMARY_API } from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs';
import { DatePicker, Button, Space } from 'antd';

import { Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4';

import { Legend, TimeScale } from 'chart.js';

Chart.register(
    TimeScale,
    Legend,
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: {
                    minute: 'HH',
                    hour: 'ddd HH:mm'
                },
                round: 'hour',
                tooltipFormat: 'DD T'
            },
            title: {
                display: false,
            },
        },
        y: {
            min: 70,
        },
    },
}

const PulseOxChart = ({ className, uid }) => {
    let initData: any[] = []
    const [series, setSeries] = useState({ datasets: initData })
    const [date, setDate] = useState(dayjs())

    const handleBack = () => {
        setDate(date.subtract(1, 'day'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'day'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }

    useEffect(() => {
        axios.get(PULSEOX_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
            .then(response => {
                let data = response.data.data.map(item => {
                    return {
                        x: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                        y: [item.min_spo2 - 2, item.max_spo2 + 2],
                    }
                })
                setSeries({
                    datasets: [{
                        data: data,
                        borderRadius: Number.MAX_VALUE,
                        borderSkipped: false,
                        categoryPercentage: 0.4,
                        backgroundColor: '#F1416C'
                    }]
                })
            })
            .catch(error => console.log(error))
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Oxy máu</span>

                    <span className='text-muted fw-semibold fs-7'></span>
                </h3>

                {/* begin::Toolbar */}
                <div className='card-toolbar' data-kt-buttons='true'>
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker width={100} value={date} onChange={handleDateSelect} allowClear={false}/>
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body' style={{ height: "500px" }}>
                <Bar
                    options={options}
                    data={series}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { PulseOxChart }