import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { PatientModel, patientModelInitValues as initialValues } from './PatientModel'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { PROFILE_API } from '../../../helpers/Common'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const patientSchema = Yup.object().shape({
    fullname: Yup.string().required('Họ và tên là bắt buộc'),
    phone: Yup.string().required('Số điện thoại là bắt buộc'),
    email: Yup.string().required('Email là bắt buộc'),
    gender: Yup.string().required('Giới tính là bắt buộc'),
    birthday: Yup.string(),
    address: Yup.string(),
})

const PatientSettings = ({ account, requestUpdate }) => {
    // console.log('setting', account)
    const navigate = useNavigate()

    const [data, setData] = useState<PatientModel>(initialValues)
    const updateData = (fieldsToUpdate: Partial<PatientModel>): void => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
    }

    const [loading, setLoading] = useState(false)
    const formik = useFormik<PatientModel>({
        initialValues: { ...initialValues, ...account },
        validationSchema: patientSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            console.log(values)
            axios.put(PROFILE_API, {
                target_user_id: account.uid,
                fullname: values.fullname,
                phone: values.phone,
                email: values.email,
                gender: values.gender,
                birthday: values.birthday?.replaceAll('-', '/'),
                address: values.address,
            }).then(response => {
                console.log('rep', response)
                setLoading(false)
                requestUpdate()
                navigate('../')
            })
        }
    })
    // setTimeout(() => {
    //     const updatedData = Object.assign(data, values)
    //     // setData(updatedData)
    //     console.log(updateData)
    //     setLoading(false)
    // }, 1000)
    // },
    // })

    return (
        account && <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Thông tin khách hàng</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                            <div className='col-lg-8'>
                                <div
                                    className='image-input image-input-outline'
                                    data-kt-image-input='true'
                                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                >
                                    <div
                                        className='image-input-wrapper w-125px h-125px'
                                        style={{ backgroundImage: `url(${account.avatar ? account.avatar : toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Họ và tên</label>

                            <div className='col-lg-8'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Nguyễn Văn A'
                                    {...formik.getFieldProps('fullname')}
                                />
                                {formik.touched.fullname && formik.errors.fullname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.fullname}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Ngày sinh</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='date'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='0123456789'
                                    {...formik.getFieldProps('birthday')}
                                />
                                {formik.touched.birthday && formik.errors.birthday && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.birthday}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Số điện thoại</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='0123456789'
                                    {...formik.getFieldProps('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.phone}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='doctor@metadoc.vn'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Địa chỉ</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='186 Điện Biên Phủ'
                                    {...formik.getFieldProps('address')}
                                />
                            </div>
                        </div>                        

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Giới tính</label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('gender')}
                                >
                                    <option value=''>Chọn giới tính...</option>
                                    <option value='Male'>Nam</option>
                                    <option value='Female'>Nữ</option>
                                </select>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.gender}</div>
                                    </div>
                                )}

                                <div className='form-text'>
                                    Điền giới tính sinh học của khách hàng
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { PatientSettings }
