/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem to='/dashboard' icon='chart-pie-3' title='Tổng quan' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>NGHIỆP VỤ</span>
        </div>
      </div>
      <AsideMenuItem to='/patient/list' icon='profile-user' title='Khách hàng' />
      <AsideMenuItem to='/patient/messages' icon='messages' title='Tin nhắn' />
      <AsideMenuItem to='/calendar' icon='calendar' title='Lịch làm việc' />

      <AsideMenuItemWithSub to='/crafted/widgets' title='Khách hàng mẫu' icon='element-plus'>
        <AsideMenuItem to='/patient/id/9b1af3ee9cc24b3e91f52202f1627248/overview' title='Bùi Đình Chiến' hasBullet={true} />
        <AsideMenuItem to='/patient/id/d80ba2e3ba78431687c63a7247336356/overview' title='Trần Tám' hasBullet={true} />
        <AsideMenuItem to='/patient/id/1fe7e0b650b04a8083266ad0e74a3ced/overview' title='Phan Hồng Phi' hasBullet={true} />
        <AsideMenuItem to='/patient/id/0d6ac56420a64bb7816ed1302028d28b/overview' title='Vũ Thị Thu Hà' hasBullet={true} />
        <AsideMenuItem to='/patient/id/e429d34835764b5d9ce4f621f38ce8b6/overview' title='Lê Anh Tuấn' hasBullet={true} />
        <AsideMenuItem to='/patient/id/e26686a1f29c482ab4833fdb3f5fc5f4/overview' title='Trần Đăng Thuần' hasBullet={true} />
        <AsideMenuItem to='/patient/id/2d8981e076ed44b191b477c939ce79b4/overview' title='Nguyễn Vĩnh Tùng' hasBullet={true} />
        <AsideMenuItem to='/patient/id/53999a5e8d5b4b638570147fe1dd1db9/overview' title='Đinh Thị Chung' hasBullet={true} />
        <AsideMenuItem to='/patient/id/75d3b092481a4a93a6dfa6fcf5007fa4/overview' title='Nguyễn Hoài Nam' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItemWithSub to='/crafted/pages' title='Bệnh nhân' icon='profile-user'>
        <AsideMenuItem
          to='/patient/list'
          title='Danh sách bệnh nhân'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/patient/follow'
          title='Đang theo dõi'
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub to='/crafted/pages' title='Hồ sơ' icon='gift'></AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>HỆ THỐNG</span>
        </div>
      </div>
      <AsideMenuItem to='/account/list' icon='shield-tick' title='Tài khoản' />
      <AsideMenuItem to='/permissions' icon='key' title='Phân quyền' />
      {/* <AsideMenuItemWithSub to='/crafted/pages' title='Tài khoản' icon='user-square'></AsideMenuItemWithSub>
      <AsideMenuItem
          to='/patient/list2'
          title='Quản lý tài khoản'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/crafted/account/overview'
          title='Tài khoản của tôi'
          hasBullet={true}
        />      
      <AsideMenuItemWithSub to='/crafted/pages' title='Cài đặt' icon='setting-3'></AsideMenuItemWithSub>
      <AsideMenuItem to='/apps/user-management/users' icon='shield-tick' title='User management' /> */}
      {/* <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub to='/crafted/pages' title='Pages' icon='gift'>
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' icon='profile-circle'>
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/error' title='Errors' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' icon='element-plus'>
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub to='/apps/chat' title='Chat' icon='message-text-2'>
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to='/apps/user-management/users' icon='shield-tick' title='User management' />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='document' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
