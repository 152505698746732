import { useNavigate } from 'react-router-dom'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { AccountsListFilter } from './AccountsListFilter'

const AccountsListToolbar = () => {
  // const {setItemIdForUpdate} = useListView()
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }

  const navigate = useNavigate();

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <AccountsListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Tải danh sách
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={() => {navigate('/account/new')}}>
        <KTIcon iconName='plus' className='fs-2' />
        Thêm tài khoản
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {AccountsListToolbar}
