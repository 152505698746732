export interface PatientModel {
    avatar: string
    fullname: string
    phone: string
    email: string
    gender: string
    birthday?: string
    address?: string
  }

export const patientModelInitValues: PatientModel = {
    avatar: '/media/avatars/300-1.jpg',
    fullname: 'Max',
    phone: '0123456789',
    email: 'email@liame.com',
    gender: 'Male',
    birthday: '1970-01-01',
    address: '',
  }
  