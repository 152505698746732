import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  id?: ID
  fullname?: string
  avatar?: string
  email?: string
  phone?: string
  role?: string
  birthday?: string
  age?: number
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  gender?: string
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  avatar: 'avatars/300-6.jpg',
  phone: 'Không có',
  role: 'Administrator',
  fullname: 'Không có',
  email: 'Không có',
}
