/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { PULSEOX_SUMMARY_API, RESPIRATION_SUMMARY_API, HEARTRATE_SUMMARY_API, STEP_SUMMARY_API } from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs';
import { DatePicker, Button, Space } from 'antd';

import { Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4';

import { Legend, TimeScale } from 'chart.js';

Chart.register(
    TimeScale,
    Legend,
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'hour' as const,
                displayFormats: {
                    minute: 'HH' as const,
                    hour: 'ddd HH:mm' as const,
                },
                round: 'hour' as const,
                tooltipFormat: 'DD T' as const,
            },
            title: {
                display: false,
            },
        },
        y : {
            type: 'linear' as const,
        },
        y1 : {
            type: 'linear' as const,
            position: 'right' as const,
        }
        // y: {
        //     min: 70,
        // },
    },
}

const ComboChart = ({ className, uid }) => {
    let initData: any[] = []
    const [series, setSeries] = useState({ datasets: initData })
    const [date, setDate] = useState(dayjs())

    const handleBack = () => {
        setDate(date.subtract(1, 'day'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'day'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }

    useEffect(() => {
        let pulseOxData = axios.get(PULSEOX_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
        let respirationData = axios.get(RESPIRATION_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
        let heartRateData = axios.get(HEARTRATE_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
        let stepData = axios.get(STEP_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })

        Promise.all([pulseOxData, respirationData, heartRateData, stepData]).then((values) => {
            let [pulseOxData, respirationData, heartRateData, stepData] = values
            let pulseChart = pulseOxData.data.data.map(item => {
                return {
                    x: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.avg_spo2,
                }
            })

            let respirationChart = respirationData.data.data.map(item => {
                return {
                    x: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.avg_respiration,
                }
            })

            let heartRateChart = heartRateData.data.data.map(item => {
                return {
                    x: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.average_heart_rate_in_beats_per_minute,
                }
            })

            let stepChart = stepData.data.data.map(item => {
                return {
                    x: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.total_steps,
                }
            })

            // console.log('chart', respirationChart)
            setSeries({
                datasets: [
                    {
                        type: 'line',
                        data: pulseChart,
                        backgroundColor: '#F1416C',
                    },
                    {
                        type: 'line',
                        data: respirationChart,
                        backgroundColor: '#3E97FF', 
                    },
                    {
                        type: 'line',
                        data: heartRateChart,
                        backgroundColor: '#75CC68',
                    },
                    {
                        type: 'bar',
                        data: stepChart,
                        backgroundColor: '#FFAD0F',
                        yAxisID: 'y1',      
                        borderRadius: Number.MAX_VALUE,      
                        categoryPercentage: 0.4,            
                    }
                ]
            })
        })
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Tổng hợp</span>

                    <span className='text-muted fw-semibold fs-7'></span>
                </h3>

                {/* begin::Toolbar */}
                <div className='card-toolbar' data-kt-buttons='true'>
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} onChange={handleDateSelect} allowClear={false}/>
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body' style={{ height: "500px" }}>
                <Bar
                    options={options}
                    data={series}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { ComboChart }