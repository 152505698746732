import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ACCOUNT_API, AVATAR_API, PROFILE_API } from '../../../helpers/Common'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AccountModel, accountModelInitValues as initialValues } from './AccountModel'

const accountSchema = Yup.object().shape({
    fullname: Yup.string().required('Họ và tên là bắt buộc'),
    phone: Yup.string().required('Số điện thoại là bắt buộc'),
    email: Yup.string().required('Email là bắt buộc'),
    gender: Yup.string(),
    role: Yup.string().required('Loại tài khoản là bắt buộc'),
    password: Yup.string().required('Mật khẩu là bắt buộc'),
    birthday: Yup.string()
})

const NewAccountPage = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const formik = useFormik<AccountModel>({
        initialValues,
        validationSchema: accountSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            let newAccount = { ...values }
            newAccount.birthday = newAccount.birthday?.replaceAll('-', '/')
            console.log('new account', newAccount)
            axios.post(ACCOUNT_API, newAccount)
                .then(response => {
                    console.log('rep', response)
                    setLoading(false)
                    if (response.data.success) {
                        navigate(`/patient/id/${response.data.data.uid}`)
                    };
                })
        }
    })
    // setTimeout(() => {
    //     const updatedData = Object.assign(data, values)
    //     // setData(updatedData)
    //     console.log(updateData)
    //     setLoading(false)
    // }, 1000)
    // },
    // })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Thông tin tài khoản</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Loại tài khoản</label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('role')}
                                >
                                    <option value=''>Loại tài khoản...</option>
                                    <option value='DOCTOR'>Bác sỹ</option>
                                    <option value='SUPER_ADMIN_BRANCH'>Quản trị viên</option>
                                    <option value='ADMIN'>Chăm sóc khách hàng</option>
                                    <option value='PATIENT'>Bệnh nhân</option>
                                </select>
                                {formik.touched.role && formik.errors.role && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.role}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mật khẩu</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='password'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    {...formik.getFieldProps('password')}
                                />
                                {formik.touched.fullname && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.password}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                            <div className='col-lg-8'>
                                <div
                                    className='image-input image-input-outline'
                                    data-kt-image-input='true'
                                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                    onChange={changeAvatar}
                                >
                                    <div
                                        className='image-input-wrapper w-125px h-125px'
                                        style={{ backgroundImage: `url(${account.avatar ? account.avatar : toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                    ></div>
                                    <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                        data-kt-image-input-action="change"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                        title="Change avatar">
                                        <i className="ki-duotone ki-pencil fs-6"><span className="path1"></span><span className="path2"></span></i>

                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                        <input type="hidden" name="avatar_remove" />
                                    </label>
                                </div>
                            </div>
                        </div> */}

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Họ và tên</label>

                            <div className='col-lg-8'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Nguyễn Văn A'
                                    {...formik.getFieldProps('fullname')}
                                />
                                {formik.touched.fullname && formik.errors.fullname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.fullname}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Ngày sinh</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='date'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='0123456789'
                                    {...formik.getFieldProps('birthday')}
                                />
                                {formik.touched.birthday && formik.errors.birthday && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.birthday}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Số điện thoại</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='0123456789'
                                    {...formik.getFieldProps('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.phone}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='doctor@metadoc.vn'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                            <div className='col-lg-8 fv-row'>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon3">
                                        <i className="ki-duotone ki-lock-3 fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
                                    </span>
                                    <input type="text" className="form-control form-control-lg" id="basic-url" value={account && account.email} aria-describedby="basic-addon3" disabled />
                                </div>

                            </div>
                        </div> */}

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Giới tính</label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('gender')}
                                >
                                    <option value=''>Chọn giới tính...</option>
                                    <option value='Male'>Nam</option>
                                    <option value='Female'>Nữ</option>
                                </select>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.gender}</div>
                                    </div>
                                )}

                                <div className='form-text'>
                                    Điền giới tính sinh học của khách hàng
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Tạo tài khoản'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { NewAccountPage }
