import React, { useEffect, useReducer, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import axios from 'axios'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { PROFILE_API } from '../../../helpers/Common'
import { useAuth } from '../../../auth/core/Auth'
import { AccountHeader } from './AccountHeader'
import { AccountOverview } from './AccountOverview'
import { AccountSettings } from './AccountSettings'
import { AccountSecurity } from './AccountSecurity'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Danh sách tài khoản',
    path: '/account/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PatientPage = () => {
  //   const [account, setAccount] = useState()
  //   const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  //   const { uid } = useParams()
  const { currentUser, setCurrentUser } = useAuth()

  //   useEffect(() => {
  // axios.get(PROFILE_API, { params: { target_user_id: uid } }).then(response => response.data.payload.data).then(
  //   data => {
  //     if (data.birthday) data.birthday = data.birthday.replaceAll('/', '-');
  //     setAccount(data)
  //   }
  // )
  //   }, [uid, counter])

  return (
    <Routes>
      <Route
        element={
          <>
            <AccountHeader account={currentUser} />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Tổng quan</PageTitle>
              <AccountOverview account={currentUser} />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
              <AccountSettings account={currentUser} />
              <AccountSecurity account={currentUser} />
            </>
          }
        />
        <Route index element={<Navigate to={`overview`} />} />
      </Route>
    </Routes>
  )
}

export default PatientPage
