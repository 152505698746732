import { Link } from 'react-router-dom'
import { MEDIAL_FILES_API, getGenderDescription } from '../../../helpers/Common'
import { KTIcon } from '../../../../../_metronic/helpers/components/KTIcon'
import { useCallback, useEffect, useReducer, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { Button, Empty, Modal, Space } from 'antd'
import { useDropzone } from 'react-dropzone'

const initialData: any[] = []

export function PatientProfile({ account }) {
  const [page, setPage] = useState(1)
  const [files, setFiles] = useState(initialData)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [fileList, setFileList] = useState(initialData);
  const [fileTitle, setFileTitle] = useState('');
  const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleTitleChange = (e) => {
    setFileTitle(e.target.value)
  }
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFileList([...fileList, ...acceptedFiles])
    }
  }, [fileList])

  const handleDeleteFile = (index) => {
    let newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  const handleDeleteFolder = (uid) => {
    axios.delete(MEDIAL_FILES_API, { data: { medical_history_file_id: uid } })
    .then(response => {
      console.log(response)
      forceUpdate();
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const showModalUpload = () => {
    setIsModalUploadOpen(true);
  }

  const handleOkUpload = () => {
    console.log(fileList)
    console.log(fileTitle)
    let formData = new FormData();
    formData.append('title', fileTitle);
    for (let i = 0; i < fileList.length; i++) {
      formData.append('files', fileList[i]);
    }
    formData.append('target_user_id', account.uid);
    setConfirmLoading(true)
    axios.post(MEDIAL_FILES_API, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        console.log(response)
        setIsModalUploadOpen(false);
        setFileTitle('');
        setFileList([]);  
        setConfirmLoading(false)      
        forceUpdate();
      })
  }

  const handleCancelUpload = () => {
    setIsModalUploadOpen(false);
    setFileTitle('');
    setFileList([]);
  }

  const showModal = (index) => {
    setIsModalOpen(true);
    setModalIndex(index);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    account && axios.get(MEDIAL_FILES_API, { params: { page: page, target_user_id: account.uid } })
      .then(response => {
        console.log('files', response)
        setFiles(response.data.data.items)
      })
  }, [account, counter])

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Thông tin khách hàng</h3>
          </div>

          <Link to='../settings' className='btn btn-sm btn-primary align-self-center'>
            Sửa thông tin
          </Link>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Tên đầy đủ</label>
                <div className='col-lg-9'>
                  <span className='fw-bolder fs-6 text-dark'>{account && account.fullname}</span>
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Ngày sinh</label>
                <div className='col-lg-9 fv-row'>
                  <span className='fw-bolder fs-6 me-2'>{
                    account && account.birthday ?
                      `${dayjs(account.birthday).format('DD-MM-YYYY')} (${dayjs().diff(account.birthday, 'year')} tuổi)`
                      : 'Không có thông tin'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Địa chỉ</label>
                <div className='col-lg-9 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{account && account.address ? account.address : 'Không có thông tin'}</span>
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Giới tính</label>
                <div className='col-lg-9 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{account && getGenderDescription(account.gender)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {account && (
        <>
          <div className="card card-xxl-stretch mb-5 mb-xl-10">
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Hồ sơ y tế</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Chưa cập nhật</span>
              </h3>
              <div className='card-toolbar'>
                <a role="button" className='btn btn-sm btn-light-primary' onClick={showModalUpload}>
                  <KTIcon iconName='folder-up' className='fs-2' />
                  Thêm hồ sơ
                </a>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                {(files && files.length > 0) ? <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      {/* <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='1'
                            data-kt-check='true'
                            data-kt-check-target='.widget-13-check'
                          />
                        </div>
                      </th> */}
                      <th className='min-w-150px'>Tên hồ sơ</th>
                      {/* <th className='min-w-140px'>Country</th> */}
                      <th className='min-w-120px'>Loại hồ sơ</th>
                      <th className='min-w-120px'>Tệp đính kèm</th>
                      <th className='min-w-120px'>Ngày tải lên</th>
                      {/* <th className='min-w-120px'>Status</th> */}
                      <th className='min-w-100px text-end'>Thao tác</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {
                      files && files.map((item, index) => {
                        return (
                          // <tr key={item.uid}><td>{item.title}</td></tr>
                          <tr key={item.uid}>
                            <td>
                              <a role="button" className='text-dark fw-bold text-hover-primary fs-6' onClick={() => showModal(index)}>
                                <Space>
                                  <KTIcon iconName='folder' className="fs-2x" />
                                  {item.title}
                                </Space>
                              </a>
                            </td>
                            <td>
                              <a href='#' className='text-muted fw-bold text-hover-primary d-block mb-1 fs-6'>
                                Hồ sơ tổng hợp
                              </a>
                              {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: PH</span> */}
                            </td>
                            <td className='text-muted fw-bold text-hover-primary fs-6'>{item.data.length}</td>
                            <td>
                              <a href='#' className='text-muted fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {dayjs(item.uploaded_at * 1000).format('DD-MM-YYYY HH:mm')}
                              </a>
                              {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: Paid</span> */}
                            </td>
                            <td className='text-end'>
                              <a role="button" className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={() => showModal(index)}>
                                <KTIcon iconName='eye' className='fs-3' />
                              </a>
                              <a role="button" className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' onClick={() => handleDeleteFolder(item.uid)}>
                                <KTIcon iconName='trash' className='fs-3' />
                              </a>
                            </td>
                          </tr>
                        )

                      })
                    }
                  </tbody>
                  {/* end::Table body */}
                </table>
                  : <Empty description={<h3 className='text-muted'>Chưa có hồ sơ</h3>} className='mb-7' />}
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
          {
            files[modalIndex] && <Modal title={files[modalIndex].title} open={isModalOpen} onOk={handleOk} cancelButtonProps={{ hidden: true }} onCancel={handleCancel} >
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Tên hồ sơ</th>
                    <th className='min-w-100px text-end'>Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    files[modalIndex].data.map((url, index) => {
                      return (
                        <tr key={url}>
                          <td>
                            <a role="button" className='text-dark fw-bold text-hover-primary fs-6'>
                              <Space>
                                <KTIcon iconName='document' className="fs-2x" />
                                {files[modalIndex].title} - {index}
                              </Space>
                            </a>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </a>
                            <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' download={true} href={url}>
                              <KTIcon iconName='folder-down' className='fs-3' />
                            </a>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
                {/* end::Table body */}
              </table>
            </Modal>
          }
          <Modal title="Tải lên hồ sơ mới" open={isModalUploadOpen} onOk={handleOkUpload} onCancel={handleCancelUpload} confirmLoading={confirmLoading} okText="Tải lên">
            <div className="form-floating pb-7 mt-7">
              <input required type="text" className="form-control" id="fileTitle" placeholder="Kết quả xép nghiệm" value={fileTitle} onChange={handleTitleChange} />
              <label htmlFor="fileTitle">Tên hồ sơ</label>
            </div>

            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Tên file</th>
                  <th className='min-w-100px text-end'>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                {
                  fileList.length > 0 ? fileList.map((file, index) => {
                    return (
                      <tr key={file.name}>
                        <td className='text-muted'>{file.name}</td>
                        <td className='text-end'>
                          <a role="button" className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' onClick={() => handleDeleteFile(index)}>
                            <KTIcon iconName='trash' className='fs-3' />
                          </a>
                        </td>
                      </tr>
                    )
                  })
                    : <tr key="empty"><td colSpan={2} className="text-muted">Chưa có file tải lên</td></tr>
                }
              </tbody>
            </table>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6" style={{ height: "200px" }}>
                <div className="d-flex flex-stack flex-grow-1">
                  <i className="ki-duotone ki-folder-up fs-4tx text-warning me-4">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <div className="fw-bold"><h4 className="text-gray-800 fw-bolder">{isDragActive ? "Thả file vào đây..." : "Tải lên"}</h4>
                    <div className="fs-6 text-gray-600">Kéo thả hoặc chọn tệp để tải lên. Hỗ trợ file ảnh định dạng (JPG) và file tài liệu định dạng (PDF).)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )
      }
    </>
  )
}
