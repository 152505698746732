import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { UserEditModal } from './patient-edit-modal/PatientEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { PatientsListHeader } from './components/header/PatientsListHeader'
import { PatientsTable } from './table/PatientsTable'

const PatientsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <PatientsListHeader />
        <PatientsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const PatientsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PatientsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { PatientsListWrapper }
