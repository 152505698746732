/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { formatPhone, getGenderDescription } from '../../../helpers/Common'
import { StepChart } from '../health/StepChart'
import { HeartRateChart } from '../health/HeartRateChart'
import { SleepChart } from '../health/SleepChart'
import { HeartRateDayChart } from '../health/HeartRateDayChart'
import { RespirationRateChart } from '../health/RespirationRateChart'
import { StepMonthChart } from '../health/StepMonthChart'
import { PulseOxChart } from '../health/PulseOxChart'
import { ComboChart } from '../health/ComboChart'
import { BloodPressureChart } from '../health/BloodPressureChart'
import { BloodGlucoseChart } from '../health/BloodGlucoseChart'
import { StressChart } from '../health/StressChart'

export function PatientHealth({ account }) {
  return (
    <>
      {account && (
        <>
          {/* <div className='row mb-1'> */}
          <ComboChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          <BloodPressureChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          <BloodGlucoseChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          <StressChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          <StepMonthChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          <PulseOxChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          {/* </div> */}

          {/* <div className='row mb-1'>
            <div className='col-xl-6'>
              <StepChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            </div>
            <div className='col-xl-6'>
              <HeartRateChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            </div>
          </div> */}
          {/* <div className='row mb-1'> */}
          {/* <div className='col-xl-6'> */}
          <SleepChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          {/* </div> */}
          {/* <div className='col-xl-6'> */}
          {/* <HeartRateDayChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* </div> */}
          {/* <div className='col-xl-6'>
              <HeartRateChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            </div> */}
          {/* </div> */}
          {/* <div className='row mb-1'> */}
          <HeartRateDayChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          {/* </div> */}
          {/* <div className='row mb-1'> */}
          <RespirationRateChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          {/* </div> */}

        </>
      )}

      {/* <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div> */}
    </>
  )
}
