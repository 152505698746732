// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './PatientnfoCell'
import {UserLastLoginCell} from './PatientLastLoginCell'
import {UserTwoStepsCell} from './PatientTwoStepsCell'
import {UserActionsCell} from './PatientActionsCell'
import {UserSelectionCell} from './PatientSelectionCell'
import {UserCustomHeader} from './PatientCustomHeader'
import {UserSelectionHeader} from './PatientSelectionHeader'
import {User} from '../../core/_models'
import { PatientPathologiesCell } from './PatientPathologiesCell'

const patientsColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Khách hàng' className='min-w-150px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Giới tính' className='min-w-100px' />,
    accessor: 'gender',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Tuổi' className='min-w-75px' />,
    accessor: 'age',
  },  
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Bệnh' className='min-w-125px' />,
  //   accessor: 'pathological_medical_history',
  // },  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Bệnh lý' className='min-w-125px' />
    ),
    id: 'pathologies',
    Cell: ({...props}) => <PatientPathologiesCell pathologies={props.data[props.row.index].pathologies} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Kế hoạch sức khoẻ' className='min-w-125px' />,
    accessor: 'existed_careplan',
  },  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Truy cập' className='min-w-125px' />
    ),
    id: 'last_online',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_online} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Nhóm bệnh' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {patientsColumns}
