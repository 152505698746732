/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Account} from '../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  user: Account
}

const AccountInfoCell = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <Link to={`/patient/id/${user.uid}`}>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={user.avatar} alt={user.fullname} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.initials?.state}`,
              `text-${user.initials?.state}`
            )}
          >
            {user.initials?.label}
          </div>
        )}
      </Link>
    </div>
    <div className='d-flex flex-column'>
      <Link to={`/patient/id/${user.uid}`} className='text-gray-800 text-hover-primary mb-1'>
        {user.fullname}
      </Link>
      <span>{user.phone}</span>
    </div>
  </div>
)

export {AccountInfoCell}
