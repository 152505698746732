import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { KTCard } from '../../../../../_metronic/helpers'
import { AccountsTable } from './table/AccountsTable'
import { AccountsListHeader } from './components/header/AccountsListHeader'

const AccountsList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <AccountsListHeader />
        <AccountsTable />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const AccountListPage = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AccountsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AccountListPage }
