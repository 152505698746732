/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../../_metronic/partials'
import { formatPhone, onlineStatus, shortListPathologies, stylePathology } from '../../../helpers/Common'
import clsx from 'clsx'

const PatientHeader = ({ account }) => {
  const location = useLocation()
  console.log('account', account)
  let pathologies = account.health.pathological_medical_history.split(';');

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={account.avatar ? account.avatar : '/media/avatars/blank.png'} alt='Metornic' />
                <div className={`position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px ${onlineStatus(account.last_online_at)}`}></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {account.fullname ? account.fullname : `Không tên (${account.phone})`}
                  </a>
                  <a href='#'>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <KTIcon iconName='note-2' className='fs-4 me-1' />
                    {
                      pathologies && shortListPathologies(pathologies).map((pathology) => (
                        <Fragment key={pathology}>
                          <span className={clsx('badge', 'fw-bolder', stylePathology(pathology))}>{pathology}</span>
                          {' '}
                        </Fragment>
                      ))
                    }
                  </span>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='whatsapp' className='fs-4 me-1' />
                    {formatPhone(account.phone)}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {account.email}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>
                {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTIcon iconName='check' className='fs-3 d-none' />

                  <span className='indicator-label'>Follow</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a> */}
                <a
                  href='#'
                  className='btn btn-sm btn-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_offer_a_deal'
                >
                  Theo dõi
                </a>
                {/* <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  <Dropdown1 />
                </div> */}
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                      <div className='fs-2 fw-bolder'>{account.health.heart_rate > 0 ? account.health.heart_rate : '--'}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Nhịp tim</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' /> */}
                      <div className='fs-2 fw-bolder'>{account.health.systolic > 0 ? `${account.health.systolic}/${account.health.diastolic}` : '--/--'}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Huyết áp</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                      <div className='fs-2 fw-bolder me-2'>{account.health.blood_glucose > 0 ? account.health.blood_glucose : '--'}</div>
                      <div className='fs-5 fw-bolder'>mg/dL</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Đường huyết</div>
                  </div>
                </div>
              </div>

              {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/patient/id/${account.uid}/overview` && 'active')
                }
                to={`/patient/id/${account.uid}/overview`}
              >
                Tổng quan
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/patient/id/${account.uid}/profile` && 'active')
                }
                to={`/patient/id/${account.uid}/profile`}
              >
                Hồ sơ
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/patient/id/${account.uid}/health` && 'active')
                }
                to={`/patient/id/${account.uid}/health`}
              >
                Chỉ số sức khoẻ
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/patient/id/${account.uid}/careplan` && 'active')
                }
                to={`/patient/id/${account.uid}/careplan`}
              >
                Kế hoạch sức khoẻ
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/patient/id/${account.uid}/activity` && 'active')
                }
                to={`/patient/id/${account.uid}/activity`}
              >
                Hoạt động
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { PatientHeader }
