export const getRoleDescription = (role) => {
    switch (role) {
        case 'SUPER_ADMIN_BRANCH': return 'Quản trị viên';
        case 'OWNER': return 'Quản trị hệ thống';
        case 'DOCTOR': return 'Bác sĩ';
        case 'ADMIN': return 'Chăm sóc khách hàng';
        case 'PATIENT': return 'Bệnh nhân';
    }
}

export const getGenderDescription = (gender) => {
    switch (gender) {
        case 'Male': return 'Nam';
        case 'Female': return 'Nữ';
        case 'UNKNOWN': return 'Khác';
        default: return gender;
    }
}

export const getRelativeTime = (unixTimeStamp) => {
    // console.log('getRelativeTime', unixTimeStamp)
    let targetTime = new Date(unixTimeStamp * 1000);
    let currentTime = Date.now();

    let diff = (currentTime - targetTime.getTime()) / 1000;
    if (diff < 60) {
        return 'Vừa xong';
    } else if (diff < 3600) {
        return `${Math.floor(diff / 60)} phút trước`;
    } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)} giờ trước`;
    } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)} ngày trước`;
    } else if (diff < 2592000) {
        return `${Math.floor(diff / 604800)} tuần trước`;
    } else if (diff < 31536000) {
        return `${Math.floor(diff / 2592000)} tháng trước`;
    } else {
        return targetTime.toLocaleDateString();
    }
}

export const onlineStatus = (unixTimeStamp) => {
    let targetTime = new Date(unixTimeStamp * 1000);
    let currentTime = Date.now();

    let diff = (currentTime - targetTime.getTime()) / 1000;
    if (diff < 600) {
        return 'bg-success';
    } else if (diff < 1800) {
        return 'bg-warning';
    } 
    return 'bg-secondary';  
}

export const formatPhone = (phone) => {
    return phone.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '($1) $2-$3-$4');
}

export const generatePagination = (page: number, maxMage: number) => {
    let pageLinks: Array<{
        label: string
        active: boolean
        url?: string | null
        page: number | null
    }> = []
    // console.log('generatePagination', page, maxMage)

    let previousPage = {
        label: "Previous",
        active: false,
        page: page > 1 ? page - 1 : null
    }

    let nextPage = {
        label: "Next",
        active: false,
        page: page < maxMage ? page + 1 : null
    }
    pageLinks.push(previousPage)
    for (let index = 1; index <= maxMage; index++) {
        // let index = parseInt(pageIndex)
        // console.log(index)
        if (index >= 1 && index <= maxMage) {
            pageLinks.push({
                label: `${index}`,
                active: index === page,
                page: index
            })
        }
    }
    pageLinks.push(nextPage)
    // console.log(pageLinks)
    return pageLinks.map((pageLink) => {
        return {
            ...pageLink,
            url: pageLink.page ? `/?page=${pageLink.page}` : null
        }
    })
}

export const shortListPathologies = (pathologies) => {
    let shortList: string[] = []
    for (let pathology of pathologies) {
        if (pathology === 'Tiểu đường' || pathology === 'Tim mạch') {
            shortList.unshift(pathology);
        } else {
            shortList.push(pathology);
        }
    }
    if (shortList.length > 2) {
        let extra = shortList.length - 2
        shortList = shortList.slice(0, 2);
        shortList.push(`+${extra}`);
    }

    return shortList;
}

export const stylePathology = (pathology) => {
    if (pathology[0] === '+') return 'badge-light-dark'
    switch (pathology) {
        case 'Tiểu đường': return 'badge-light-primary';
        case 'Tim mạch': return 'badge-light-success';
        default: return 'badge-light-danger';
    }
}

const API_URL = process.env.REACT_APP_API_URL
export const USER_API = `${API_URL}/user`
export const PATIENT_LIST_API = `${API_URL}/api/portal/v2/account`
export const PROFILE_API = `${API_URL}/api/portal/v2/account/profile`
export const PATIENT_SUMMARY_API = `${API_URL}/api/portal/v2/account/profile/summary`
export const AVATAR_API = `${API_URL}/api/portal/v2/account/avatar`
export const ACCOUNT_API = `${API_URL}/api/portal/v2/account`
export const STEP_SUMMARY_API = `${API_URL}/api/healthprofile/v2/step/summary`
export const HEARTRATE_SUMMARY_API = `${API_URL}/api/healthprofile/v2/heartrate/summary`
export const SLEEP_SUMMARY_API = `${API_URL}/api/healthprofile/v2/sleep/summary`
export const RESPIRATION_SUMMARY_API = `${API_URL}/api/healthprofile/v2/respiration/summary`
export const PULSEOX_SUMMARY_API = `${API_URL}/api/healthprofile/v2/pulseox/summary`
export const BLOODPRESSURE_SUMMARY_API = `${API_URL}/api/healthprofile/v2/bloodpressure/summary`
export const BLOODGLUCOSE_SUMMARY_API = `${API_URL}/api/healthprofile/v2/bloodglucose/summary`
export const STRESS_SUMMARY_API = `${API_URL}/api/healthprofile/v2/stress/summary`
export const MEDIAL_FILES_API = `${API_URL}/api/healthprofile/v2/medicalhistory/files`
