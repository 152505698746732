import {useListView} from '../../core/ListViewProvider'
import {PatientsListToolbar} from './PatientsListToolbar'
import {PatientsListGrouping} from './PatientsListGrouping'
import {PatientsListSearchComponent} from './PatientsListSearchComponent'

const PatientsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PatientsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <PatientsListGrouping /> : <PatientsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PatientsListHeader}
