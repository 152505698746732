import { Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import AccountPage from "./account/AccountPage"
import { AccountListPage } from "./account-list/AccountListPage"
import { NewAccountPage } from "./account/NewAccountPage"

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Tài khoản',
        path: '/account/list',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const AccountRoutes = () => {
    return (
        <Routes>
            <Route
                path='list'
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs}>Danh sách tài khoản</PageTitle>
                        <AccountListPage />
                    </>
                }
            />
            <Route
                path='me/*'
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs}>Tài khoản của tôi</PageTitle>
                        <AccountPage />
                    </>
                }
            />
            <Route
                path='new'
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs}>Tài khoản mới</PageTitle>
                        <NewAccountPage />
                    </>
                }
            />            
            <Route
                path='id/:uid/*'
                element={
                    <>
                        Implement account view here
                    </>
                }
            />
        </Routes>
    )
}