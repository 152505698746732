import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import { ChartsWidget1, ListsWidget5, TablesWidget1, TablesWidget5 } from '../../../../../_metronic/partials/widgets';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

// import Chart from 'react-apexcharts'

export const data = {
  labels: ['Đang thực hiện', 'Hoàn thành', 'Không đạt mục tiêu', 'Chưa bắt đầu'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5],
      // backgroundColor: [
      //   'rgba(255, 99, 132, 0.2)',
      //   'rgba(54, 162, 235, 0.2)',
      //   'rgba(255, 206, 86, 0.2)',
      //   'rgba(75, 192, 192, 0.2)',
      //   'rgba(153, 102, 255, 0.2)',
      //   'rgba(255, 159, 64, 0.2)',
      // ],
      // borderColor: [
      //   'rgba(255, 99, 132, 1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)',
      //   'rgba(255, 159, 64, 1)',
      // ],
      // borderWidth: 1,
    },
  ],
};


export const Careplan = () => {
  let series = [44, 55, 41, 17, 15]
  let labels = ['A', 'B', 'C', 'D', 'E']

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Kế hoạch sức khoẻ</h3>
          </div>

          <button className='btn btn-primary align-self-center' data-bs-toggle="modal" data-bs-target="#kt_modal_1">
            Thêm kế hoạch mới
          </button>
          <div className="modal fade" tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Thêm kế hoạch mới</h5>
                  <div
                    className="btn btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    {/* <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                    /> */}
                  </div>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className='row mb-7'>
                      <div className='col-xl-8'>
                        <div className="form-floating">
                          <input type="text" className="form-control" id="planTitle" placeholder="Giảm mỡ máu" />
                          <label htmlFor="planTitle">Tên của kế hoạch</label>
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className="form-floating">
                          <select className='form-select' id="priority">
                            {/* <option value=''>Chọn giới tính...</option> */}
                            <option value='HIGH'>Tập thể dục</option>
                            <option value='LOW'>2 tuần</option>
                          </select>
                          <label htmlFor="priority">Loại kế hoạch</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-7">
                      <textarea style={{ height: "150px" }} className="form-control" id="planDescription" placeholder="Giảm mỡ máu" />
                      <label htmlFor="planDescription">Mô tả kế hoạch</label>
                    </div>
                    <div className='row mb-7'>
                      <div className='col-xl-4'>
                        <div className="form-floating">
                          <select className='form-select' id="priority">
                            {/* <option value=''>Chọn giới tính...</option> */}
                            <option value='HIGH'>1 tuần</option>
                            <option value='LOW'>2 tuần</option>
                          </select>
                          <label htmlFor="priority">Thời gian thực hiện</label>
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className="form-floating">
                          <input type='date' className='form-control' id='planStartDate' />
                          <label htmlFor="planStartDate">Thời gian bắt đầu</label>
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className="form-floating">
                          <select className='form-select' id="priority">
                            {/* <option value=''>Chọn giới tính...</option> */}
                            <option value='HIGH'>Hàng ngày</option>
                            <option value='LOW'>Hàng tuần</option>
                          </select>
                          <label htmlFor="priority">Lặp lại</label>
                        </div>
                      </div>
                    </div>
                    <div className='row mb'>
                      <div className='col-xl-4'>
                        <div className="form-floating mb-7">
                          <select className='form-select' id="priority">
                            {/* <option value=''>Chọn giới tính...</option> */}
                            <option value='HIGH'>Thủ công</option>
                            <option value='LOW'>Lấy số bước chân</option>
                          </select>
                          <label htmlFor="priority">Cách nhập liệu</label>
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className="form-floating mb-7">
                          <input type="text" className="form-control" id="planTitle" placeholder="Giảm mỡ máu" />
                          <label htmlFor="planTitle">Giá trị mục tiêu</label>
                        </div>
                      </div>
                      <div className='col-xl-4'>
                        <div className="form-floating mb-7">
                        <select className='form-select' id="priority">
                            {/* <option value=''>Chọn giới tính...</option> */}
                            <option value='HIGH'>9:00 sáng</option>
                            <option value='LOW'>12:00 trưa</option>
                          </select>
                          <label htmlFor="planTitle">Nhắc nhở</label>
                        </div>
                      </div>                      
                      {/* <div className='col-xl-1 h-100'>
                        <button className='btn btn-primary btn-lg' style={{ minHeight: '50px' }}>
                          Xoá
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card-body p-9'>
          {/* 

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
              {account && account.email}
              </a>
            </div>
          </div> */}

          <div className='row mb'>
            <div className='col-xl-6'>
              <div className="card card-xxl-stretch mb-5 mb-xl-10 h-100">
                <div className='card-header border-0 pt-5 h-100 w-100'>
                  {/* <Chart
                    options={{}}
                    series={series}
                    type="donut"
                    style={{minWidth: '100%'}}
                    width="100%"
                    height="100%"
                  /> */}
                  <Doughnut data={data}
                    style={{ maxWidth: '100%', maxHeight: '400px' }}
                    options={{ maintainAspectRatio: false }}
                  />
                  {/* // width={100} */}
                  {/* // height={50} */}
                  {/* // options={{ maintainAspectRatio: false }} */}
                  {/* /> */}
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
            </div>
          </div>
        </div>
      </div>

      {/* <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <Pie className='h-50' data={data} />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div> */}

      {/* <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div> */}
    </>
  )
}
