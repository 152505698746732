import axios, { AxiosResponse } from 'axios'
import { ID, PaginationState, Response } from '../../../../../../_metronic/helpers'
import { User, UsersQueryResponse } from './_models'
import { PATIENT_LIST_API, formatPhone, generatePagination, getGenderDescription, getRelativeTime } from '../../../../helpers/Common'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
// const GET_USERS_URL = `${API_URL}/api/portal/v2/account?role=["PATIENT"]&status=ACTIVE`
// const PROFILE_API = '/api/portal/v2/account/profile'

const getUsers = async (query: string, page: number): Promise<UsersQueryResponse> => {
  var items = []

  return axios
    .get(`${PATIENT_LIST_API}?role=["PATIENT"]&status=ACTIVE&${query}`)
    .then((response: AxiosResponse) => {
      const items = response.data.data.items.map((item) => {
        return {
          ...item,
          age: item['birthday'] ? new Date().getFullYear() - new Date(item['birthday']).getFullYear() : undefined,
          initials: { label: item['fullname'][0], state: 'primary' },
          gender: getGenderDescription(item['gender']),
          last_online: getRelativeTime(item['last_online_at']),
          phone: formatPhone(item['phone']),
          pathologies: item.health.pathological_medical_history ? item.health.pathological_medical_history.split(';') : null,
          existed_careplan: item.careplan.existed_careplan ? "Đã thiết lập" : "Chưa thiết lập"        
        }
      })
      return {data: items, payload: {pagination: <PaginationState>{
        items_per_page: response.data.data.per_page,
        page: page,
        links: generatePagination(page, response.data.data.max_page)
      }} }
    })
}
//     .get(`${GET_USERS_URL}&${query}`)
//   .then((response: AxiosResponse) => {
//     items = response.data.payload.data.items;
//     let uids = items.map(item => item['uid'])
//     return axios.get(`${API_URL}${PROFILE_API}?target_user_id_list=${JSON.stringify(uids)}`)
//   })
//   .then((response: AxiosResponse) => {
//     let profiles = {}
//     for (let profile of response.data.payload.data) {
//       profiles[profile['uid']] = profile
//     }
//     console.log(profiles)
//     let users = items.map<User>(item => {
//       let uid = item['uid']
//       return {
//         fullname: item['fullname'],
//         avatar: profiles[uid]['basic_info']['avatar'],
//         email: profiles[uid]['contact_info']['email'],
//         birthday: profiles[uid]['basic_info']['birthday'],
//         phone: profiles[uid]['contact_info']['phone'],
//         age: (new Date().getFullYear() - new Date(profiles[uid]['basic_info']['birthday']).getFullYear()),
//         initials: { label: item['fullname'][0], state: 'primary' },
//         gender: getGenderDescription(profiles[uid]['basic_info']['gender'])
//       }
//     })
//     return { data: users }
//   })
// }

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser }
