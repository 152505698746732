import React, { useEffect, useReducer, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
// import {Overview} from './components/Overview'
// import {Settings} from './components/settings/Settings'
import { PatientHeader } from './PatientHeader'
import axios from 'axios'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { PATIENT_SUMMARY_API } from '../../../helpers/Common'
import { PatientOverview } from './PatientOverview'
import { PatientSettings } from './PatientSettings'
import { Careplan } from '../careplan/CareplanOverview'
import { PatientHealth } from './PatientHealth'
import { PatientProfile } from './PatientProfile'
import { PatientActivity } from './PatientActivity'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Danh sách khách hàng',
    path: '/patient/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PatientPage: React.FC = () => {
  const [account, setAccount] = useState()
  const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  const { uid } = useParams()

  // console.log('update')

  useEffect(() => {
    axios.get(PATIENT_SUMMARY_API, { params: { target_user_id: uid } }).then(response => response.data.data).then(
      data => {
        if (data.birthday) data.birthday = data.birthday.replaceAll('/', '-');
        setAccount(data)
      }
    )
  }, [uid, counter])

  return (
    <Routes>
      <Route
        element={
          <>
            {account && <PatientHeader account={account} />}
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Tổng quan</PageTitle>
              <PatientOverview account={account} />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
              <PatientSettings account={account} requestUpdate={forceUpdate} />
            </>
          }
        />
        <Route
          path='health'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Chỉ số sức khoẻ</PageTitle>
              <PatientHealth account={account} />
            </>
          }
        />
        <Route
          path='careplan'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Kế hoạch sức khoẻ</PageTitle>
              <Careplan />
            </>
          }
        />
        <Route
          path='profile'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Hồ sơ</PageTitle>
              <PatientProfile account={account} />
            </>
          }
        />
        <Route
          path='activity'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Hoạt động</PageTitle>
              <PatientActivity account={account} />
            </>
          }
        />
        <Route index element={<Navigate to={`/patient/id/${uid}/overview`} />} />
      </Route>
    </Routes>
  )
}

export default PatientPage
