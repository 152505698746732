import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/portal/v2/account/profile`
export const LOGIN_URL = `${API_URL}/api/auth/v2/portal/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  }).then((authResponse) => {
    if (authResponse.data.success) {
      return { accessToken: authResponse.data.access_token, refreshToken: undefined, role: authResponse.data.data.role }
    };
    return Promise.reject('Login failed')
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserByToken(token: string) {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
    .then(response => response.data)
    .then((userResponse) => {
      if (userResponse.success) {
        return {
          fullname: userResponse.data.fullname,
          phone: userResponse.data.phone,
          email: userResponse.data.email,
          avatar: userResponse.data.avatar,
          birthday: userResponse.data.birthday ? userResponse.data.birthday.replaceAll('/', '-') : '',
          gender: userResponse.data.gender,
        }
      };
      return Promise.reject('User not found')
    })
}
