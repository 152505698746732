/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { formatPhone, getGenderDescription } from '../../../helpers/Common'
import { StepChart } from '../health/StepChart'
import { HeartRateChart } from '../health/HeartRateChart'
import { SleepChart } from '../health/SleepChart'
import { HeartRateDayChart } from '../health/HeartRateDayChart'
import { RespirationRateChart } from '../health/RespirationRateChart'
import { StepMonthChart } from '../health/StepMonthChart'
import { PulseOxChart } from '../health/PulseOxChart'
import { ComboChart } from '../health/ComboChart'
import { StepWidget } from '../health/StepWidget'
import { SleepWidget } from '../health/SleepWidget'
import dayjs from 'dayjs'

export function PatientOverview({ account }) {
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Thông tin khách hàng</h3>
          </div>

          <Link to='../settings' className='btn btn-sm btn-primary align-self-center'>
            Sửa thông tin
          </Link>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Tên đầy đủ</label>
                <div className='col-lg-9'>
                  <span className='fw-bolder fs-6 text-dark'>{account && account.fullname}</span>
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Ngày sinh</label>
                <div className='col-lg-9 fv-row'>
                  <span className='fw-bolder fs-6'>{
                    account && account.birthday ?
                      `${dayjs(account.birthday).format('DD-MM-YYYY')} (${dayjs().diff(account.birthday, 'year')} tuổi)`
                      : 'Không có thông tin'
                  }</span>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Địa chỉ</label>
                <div className='col-lg-9 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{account && account.address ? account.address : 'Không có thông tin'}</span>
                </div>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='row'>
                <label className='col-lg-3 fw-bold text-muted'>Giới tính</label>
                <div className='col-lg-9 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{account && getGenderDescription(account.gender)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {account && (
        <>
          <div className='row mb-1'>
            <div className='col-xl-6'>
              <StepWidget className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            </div>
            <div className='col-xl-6'>
              <SleepWidget className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            </div>
          </div>
          {/* <div className='row mb-1'> */}

          {/* <ComboChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* <StepMonthChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* <PulseOxChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* </div> */}


          {/* <div className='row mb-1'> */}
          {/* <div className='col-xl-6'> */}
          {/* <SleepChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* </div> */}
          {/* <div className='col-xl-6'> */}
          {/* <HeartRateDayChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* </div> */}
          {/* <div className='col-xl-6'>
              <HeartRateChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            </div> */}
          {/* </div> */}
          {/* <div className='row mb-1'> */}
          {/* <HeartRateDayChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* </div> */}
          {/* <div className='row mb-1'> */}
          {/* <RespirationRateChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} /> */}
          {/* </div> */}

        </>
      )}

      {/* <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div> */}
    </>
  )
}
